import css from "./MpcQuestionBuildingBlock.module.scss";
import Stack from "@mui/material/Stack";
import Block from "../../../atoms/Block";
import Media from "../../../atoms/Media";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import { useState } from "react";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import clsx from "clsx";
import { MpcBlockLayout, Sorting } from "@bespeak/apollo";
import MpcAnswers from "@/components/molecules/MpcAnswers/MpcAnswers";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import FeedbackFields from "./common/Feedback/Feedback";
import QuestionFields from "./common/Question/Question";
import {
    type BuildingBlocksLabel,
    BuildingBlockType,
    type MpcQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SelectLabel from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectLabel";
import ToggleRandomize from "./common/ToggleRandomize";

export interface MpcQuestionBuildingBlockComponentProps
    extends MpcQuestionBuildingBlock {
    availableLabels: BuildingBlocksLabel[];
    onChange?: (buildingBlock: MpcQuestionBuildingBlock) => void;
}

export function MpcQuestionBuildingBlockComponent(
    props: MpcQuestionBuildingBlockComponentProps,
) {
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    const { layout = MpcBlockLayout.TwoColumns } = props;

    const [label, setLabel] = useState(props.label || null);

    type Fields = Pick<
        MpcQuestionBuildingBlock,
        | "introduction"
        | "question"
        | "instruction"
        | "buttonLabel"
        | "background"
        | "helpText"
        | "correctFeedbackText"
        | "incorrectFeedbackText"
        | "sorting"
    >;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const imageBlock = (
        <Media
            image={props.image}
            onChange={(image) => {
                props.onChange?.({
                    ...props,
                    ...(image != null && {
                        image: { id: image },
                    }),
                    ...(image === null && {
                        image: undefined,
                    }),
                });
            }}
            error={resolveError({
                errors: props.errors,
                path: "image.id",
            })}
        />
    );

    const questionError = resolveError({
        errors: props.errors,
        path: "question",
    });

    const answers = <MpcAnswers {...props} />;

    const question = (
        <QuestionFields
            questionValue={props.question}
            introductionValue={props.introduction}
            instructionValue={props.instruction}
            onChange={(value, key) => {
                props.onChange?.({
                    ...props,
                    [key]: value,
                });
            }}
            error={questionError}
        />
    );
    const feedbackFields = (
        <FeedbackFields
            hintValue={props.helpText}
            correctFeedbackValue={props.correctFeedbackText}
            incorrectFeedbackValue={props.incorrectFeedbackText}
            onChange={(value, key) => {
                props.onChange?.({
                    ...props,
                    [key]: value,
                });
            }}
        />
    );

    const answersMaybeContainingImages =
        props.type === BuildingBlockType.MPC_WITH_IMAGES;

    const layoutOptions = [
        { type: MpcBlockLayout.TwoColumns },
        { type: MpcBlockLayout.TextCentered },
        ...(answersMaybeContainingImages
            ? [
                  { type: MpcBlockLayout.TextCenteredTwoColumnGrid },
                  { type: MpcBlockLayout.TextCenteredThreeColumnGrid },
              ]
            : [
                  { type: MpcBlockLayout.ImageLeft },
                  { type: MpcBlockLayout.ImageRight },
              ]),
    ];

    const textCenteredLayouts = [
        MpcBlockLayout.TextCentered,
        MpcBlockLayout.TextCenteredTwoColumnGrid,
        MpcBlockLayout.TextCenteredThreeColumnGrid,
    ];

    const isTextCentered = textCenteredLayouts.includes(layout);

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <LayoutSelector
                        type={BuildingBlockType.MPC}
                        value={layout}
                        layoutOptions={layoutOptions}
                        onChange={(e) => {
                            props.onChange?.({
                                ...props,
                                layout: e as MpcBlockLayout,
                            });
                        }}
                    />
                    <SelectBgColor
                        {...registerControl("background")}
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                    />
                    <SelectLabel
                        options={props.availableLabels}
                        onChange={(newValue) => {
                            setLabel(newValue);
                            props.onChange?.({
                                ...props,
                                label: newValue,
                            });
                        }}
                        value={label}
                    />
                    <ToggleRandomize
                        onChange={(value) => {
                            props.onChange?.({
                                ...props,
                                sorting: value,
                            });
                        }}
                        value={props.sorting ?? Sorting.Randomized}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    {layout === MpcBlockLayout.TwoColumns ? (
                        <>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {feedbackFields}
                            </Stack>
                            {answers}
                        </>
                    ) : null}
                    {layout === MpcBlockLayout.ImageLeft ? (
                        <>
                            <Stack gap={1} flexBasis="50%">
                                {imageBlock}
                            </Stack>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {answers}
                                {feedbackFields}
                            </Stack>
                        </>
                    ) : null}
                    {layout === MpcBlockLayout.ImageRight ? (
                        <>
                            <Stack gap={4} flexBasis="50%">
                                {question}
                                {answers}
                                {feedbackFields}
                            </Stack>
                            <Stack gap={1} flexBasis="50%">
                                {imageBlock}
                            </Stack>
                        </>
                    ) : null}
                    {isTextCentered ? (
                        <>
                            <Stack gap={4} flexBasis="100%">
                                {question}
                                {answers}
                                {feedbackFields}
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default MpcQuestionBuildingBlockComponent;
